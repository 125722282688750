import { notification } from "antd";
import { RcFile } from "antd/es/upload";
import moment from "moment";

export const showNotification = (message: string, type: string) => {
  if (type === "error") {
    notification.error({ message: message });
  }
  if (type === "success") {
    notification.success({ message: message });
  }
  if (type === "warning") {
    notification.warning({ message: message });
  }
};

export const formatDateUtcToCurrentTz = (
  date: string,
  format: string = "YYYY-MM-DD HH:mm"
) => {
  return moment(date + "+00:00").format(format);
};

export const formatDate = (dateData: any, format: string = "YYYY-MM-DD") => {
  return moment(dateData).format(format);
};
export const formatDateTime = (
  momentData: any,
  format: string = "YYYY-MM-DDTHH:mm:ssZ"
) => {
  return moment(momentData).format(format);
};
export const passwordValidator = (password: string) => {
  const re =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
  if (re.test(password)) return true;
  return false;
};
export const convertToUTC = (momentData: any) => {
  return moment.utc(momentData).format();
};
export const alphaNumericPattern = /^[\p{Letter}\p{Mark}0-9 ]+$/u;
export const alphaNumericPatternDescription =
  /^[\p{Letter}\p{Mark}0-9 _.,;!"'() ]+$/u;
export const alphabetPattern = /^[\p{Letter}\p{Mark} ]+$/u;

export const capitalizeWords = (data: string) => {
  return data.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
};
export const cleanText = (text: string) => {
  return text.split(" ").join("_").toLocaleLowerCase();
};
export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const getTagColor = (key: string, type: string) => {
  if (type === "rating") {
    return key === "Hot" ? "red" : key === "Warm" ? "orange" : "blue";
  }
  return "purple";
};

export function disabledFutureDates(current: any) {
  return current && current.valueOf() > Date.now();
}

export const getParams = () => {
  return new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams: any, prop: any) => searchParams.get(prop),
  });
};

export function removeUndefinedKeys(obj: any) {
  return Object.fromEntries(
    Object.entries(obj).filter(([_, value]) => value !== undefined)
  );
}
