import { Button, Col, Drawer, Row, Space, Tabs, Tooltip } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { BiFilterAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { requestCandidateListMinimal, requestCourseInstalments, setFilters } from "../../../core/store/reducers/admission.slice";
import { getBatch, getBatchType, getCenterList, getCourseListByCenter, getExams, getMasterDropdownData, getStreams } from "../../../core/store/reducers/masters.slice";

import { CloudDownloadOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import { Client } from "../../../core/api-client";
import ContentHeaderComponent from "../../../shared/components/content-header/content-header.component";
import DataTableComponent from "../../../shared/components/data-table/data-table.component";
import GenericFormComponent from "../../../shared/components/generic-form/generic-form.component";
import { TABLE_ROWS_COUNT } from "../../../shared/constants";
import { API_COURSE_INSTALMENTS_LIST } from "../../../shared/routes/admission.routes";
import { RootState } from "../../../shared/types/common";
import { formatDate } from "../../../shared/utils/utils";
import { NextInstalmentRealize } from "./next-instalment-realize.component";


function InstalmentList() {
    const [filterVisible, setFilterVisible] = useState(false);
    const [instalmentsList, setInstalmentsList] = useState([] as any);
    const dispatch = useDispatch();
    const { instalments, filters, total } = useSelector((state: RootState) => state.admission);
    // Actions and selections
    const [sorter, setSorter] = useState({ sort_by: "", sort_type: "" });
    const [pagination, setPagination] = useState({ page: 1, limit: TABLE_ROWS_COUNT });
    const [paidType, setPaidType] = useState('unrealised' as any);
    const [payRestAmount, setPayRestAmount] = useState(null as any)
    const { screenType } = useSelector((state: RootState) => state.settings);
    const [allListDownload, setAllListDownload] = useState(null as any);
    const [loadingList, setLoadingList] = useState(false as any);
    const listDownRef: any = useRef()
    useEffect(() => {
        if (allListDownload?.length > 0) {
            listDownRef.current.link.click();
            setAllListDownload(null)
            setLoadingList(false)
        }
    }, [allListDownload])
    const downloadList = async () => {
        setLoadingList(true)
        let csvData = [] as any;
        const header = columns.filter((val: any) => val.title != 'Actions').map((val: any) => val.title)
        // csvData.push(header);
        const data = {
            page: 1, limit: 50000,
            ...sorter,
            paid_type: paidType
        };
        let filter = {} as any;
        Object.keys(filters)?.map((val: any) => {
            if (filters[val])
                filter[val] = filters[val]
        })
        const records = await Client.post(API_COURSE_INSTALMENTS_LIST, filter, { params: data });
        if (records?.data?.data) {
            records?.data?.data?.map((record: any) => {
                csvData.push({
                    'Reg. No.': record?.candidate_id?.registration_number,
                    'Roll No.': record?.course_enrolment_id?.roll_no,
                    'Name': record?.candidate_id?.candidate_name,
                    'Mobile': record?.candidate_id?.contact,
                    'Email': record?.candidate_id?.email,
                    'Paid?': record?.realized ? 'Yes' : 'No',
                    'Preferred Date': formatDate(record?.prefered_instalment_date, "DD MMM, YYYY"),
                    'Payment Date': record?.realized ? formatDate(record?.actual_payment_date, "DD MMM, YYYY") : 'Not Paid',
                    'Amount': record?.net_instalment_amount,
                    'Center': record?.course_enrolment_id?.batch_id?.center_id?.center_name,
                    'Course': record?.course_enrolment_id?.batch_id?.course_id?.course_name,
                    'Batch Type': record?.course_enrolment_id?.batch_id?.batch_type_id?.batch_type_name,
                    'Exam': record?.course_enrolment_id?.batch_id?.exam_id?.exam_name,
                    'Stream': record?.course_enrolment_id?.batch_id?.stream_id?.stream_name,
                    'Session': record?.course_enrolment_id?.batch_id?.session_id?.session,
                });
            })
        }
        if (csvData?.length == 0) {
            csvData = header
        }
        setAllListDownload(csvData)
    }

    useEffect(() => {
        loadList();
    }, [])

    const loadList = useCallback(
        (_page: any = 1) => {
            const data = {
                ...pagination,
                ...sorter,
                paid_type: paidType
            };
            dispatch(requestCourseInstalments({ params: data, body: filters }));
        },
        [filters, dispatch, sorter, pagination, paidType]
    );
    useEffect(() => {
        loadList();
    }, [loadList]);

    useEffect(() => {
        if (instalments?.length > 0) {
            setInstalmentsList(
                instalments.map((obj: any, index: number) => {
                    return { key: index, ...obj };
                })
            );
        } else {
            setInstalmentsList([])
        }
    }, [instalments]);
    const onPageChange = (pageNumber: number) => {
        setPagination({
            page: pageNumber,
            limit: pagination.limit,
        });
    };

    const columns = [
        {
            title: 'Reg. No.',
            dataIndex: ['candidate_id', 'registration_number'],
            key: 'candidate_name',
        },
        {
            title: 'Roll No.',
            render: (data: any) => {
                // return data?.realized ? data?.course_enrolment_id?.roll_no : 'NA'
                return data?.course_enrolment_id?.roll_no
            },
            key: 'roll_no',
        },
        {
            title: 'Name',
            dataIndex: ['candidate_id', 'candidate_name'],
            key: 'candidate_name',
        },
        {
            title: 'Mobile',
            dataIndex: ['candidate_id', 'contact'],
            key: 'contact',
        },
        {
            title: 'Email',
            dataIndex: ['candidate_id', 'email'],
            key: 'email',
        },
        {
            title: 'Paid?',
            dataIndex: 'realized',
            render: (data: any) => {
                return data ? 'Yes' : 'No'
            },
            key: 'realized',
        },
        {
            title: 'Preferred Date',
            render: (data: any) => {
                return formatDate(data.prefered_instalment_date, "DD MMM, YYYY")
            },
            key: 'prefered_instalment_date',
        },
        {
            title: 'Payment Date',
            render: (data: any) => {
                return data.realized ? formatDate(data.actual_payment_date, "DD MMM, YYYY") : 'Not Paid'
            },
            key: 'actual_payment_date',
        },
        {
            title: 'Amount',
            dataIndex: 'net_instalment_amount',
            key: 'net_instalment_amount',
        },
        {
            title: 'Center',
            dataIndex: ["course_enrolment_id", 'batch_id', 'center_id', 'center_name'],
            key: 'center_name',
        },
        {
            title: 'Course',
            dataIndex: ["course_enrolment_id", 'batch_id', 'course_id', 'course_name'],
            key: 'course_name',
        },
        {
            title: 'Batch Type',
            dataIndex: ["course_enrolment_id", 'batch_id', 'batch_type_id', 'batch_type_name'],
            key: 'batch_type_name',
        },
        {
            title: 'Exam',
            dataIndex: ["course_enrolment_id", 'batch_id', 'exam_id', 'exam_name'],
            key: 'exam_name',
        },
        {
            title: 'Stream',
            dataIndex: ["course_enrolment_id", 'batch_id', 'stream_id', 'stream_name'],
            key: 'stream_name',
        },
        {
            title: 'Session',
            dataIndex: ["course_enrolment_id", 'batch_id', 'session_id', 'session'],
            key: 'session',
        },
        {
            title: 'Action',
            // dataIndex: 'address',
            fixed: 'right',
            render: (a: any) => {
                return (
                    <>
                        {/* <Tooltip title={"Edit"}>
                            <Button
                                type="link"
                                className="table-button"
                                icon={<EditOutlined />}
                            // onClick={() => openAddEditPanel(a)}
                            ></Button>
                        </Tooltip>
                        <Tooltip title={"Details"}>
                            <Button
                                // onClick={() => navigate("/enquiry-details/" + a._id)}
                                type="link"
                                className="table-button"
                                icon={<ArrowRightOutlined />}
                            ></Button>
                        </Tooltip> */}
                        {
                            paidType === "unrealised" ? <Tooltip title={"Pay Installment"}>
                                <Button
                                    onClick={() => setPayRestAmount(a)}
                                    type="link"
                                    className="table-button"
                                >Pay</Button>
                            </Tooltip> :
                                <></>
                        }

                    </>
                );
            },
            key: "actions",
        },
    ];
    const payColumns = [
        {
            title: "S. No.",
            dataIndex: "sno",
            key: "sno",
        },
        {
            title: "Payment Mode",
            dataIndex: "payment_mode",
            key: "payment_mode",
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
        },
        {
            title: "Payment Type",
            dataIndex: "payment_type",
            key: "payment_type",
            className: "capitalize",
        },
        {
            title: "Cheque/DD/Pay Date",
            dataIndex: "actual_payment_date",
            key: "actual_payment_date",
        },
        {
            title: "EDC Charges",
            dataIndex: "edc_charges",
            key: "edc_charges",
        },

        {
            title: "Total Amount",
            dataIndex: "total_amount",
            key: "total_amount",
        },
        {
            title: "In Favor Of",
            dataIndex: "in_favor_of",
            key: "in_favor_of",
        },
        {
            title: "Bank Name",
            dataIndex: "bank_name",
            key: "bank_name",
        },
        {
            title: "Branch Name",
            dataIndex: "branch_name",
            key: "branch_name",
        },
        {
            title: "Trans / DD / Cheque No",
            dataIndex: "ref_no",
            key: "ref_no",
        },

    ];
    const headerButtons = (
        <Space>
            <Button
                shape="circle"
                type="primary"
                icon={
                    <span role="img" className="anticon">
                        <BiFilterAlt />
                    </span>
                }
                onClick={() => {
                    setFilterVisible(!filterVisible);
                }}
            ></Button>
            <Tooltip title="Max. 50000 rows can be exported at a time."><Button
                loading={loadingList}
                type="primary"
                icon={<CloudDownloadOutlined />}
                onClick={downloadList}
            >
                <span className="font-size-14">{!screenType?.isPortrait && "Download"}</span>
            </Button></Tooltip>
            <CSVLink target="_blank" data={allListDownload || []} filename="all_instalments.csv" ref={listDownRef} style={{ display: 'none' }} />
        </Space>
    );
    const onFilterApply = (values: any) => {
        let arrFilter = [];
        for (let [key, value] of Object.entries(values)) {
            if (value !== "") {
                arrFilter.push({ key: key, value: value });
            }
        }
        let refinedArray = arrFilter.filter((data) => {
            return data.value !== undefined;
        });
        let obj = {} as any;
        refinedArray.forEach((data: any) => {
            obj[data.key] = data.value;
        });
        setPagination({
            page: 1,
            limit: pagination.limit,
        });
        if (candidate_id && obj.candidate_name) {
            obj = { ...obj, candidate_id }
        }
        delete obj.candidate_name
        dispatch(setFilters(obj)); //filters
    };
    const [courseId, setCourseId] = useState(null)
    const [centerId, setCenterId] = useState(null)
    const [batchTypeId, setBatchTypeId] = useState(null)
    const [examId, setExamId] = useState(null)
    const [sessions, setSessions] = useState([] as any);
    const { centers, courses, batchType, exams, streams, batch } = useSelector((state: RootState) => state.masters);
    useEffect(() => {
        dispatch(getCenterList())
        dispatch(getMasterDropdownData({ schemaKey: "session", labelKey: "session" })).then((val: any) => {
            if (val?.payload?.data?.success) {
                setSessions(val?.payload?.data?.data?.map((val: any) => {
                    return { label: val.session, value: val._id }
                }))
            }
        })
    }, [])
    const getRelevantData = (event: any, key: any) => {
        if (event === "course") {
            setCenterId(key)
            dispatch(getCourseListByCenter({ center_id: key }))
        }
        if (event === "batchType") {
            setCourseId(key)
            dispatch(getBatchType({ center_id: centerId, course_id: key }))
        }
        if (event === "exam") {
            setBatchTypeId(key)
            dispatch(getExams({ center_id: centerId, course_id: courseId, batch_type_id: key }))
        }
        if (event === "stream") {
            setExamId(key)
            dispatch(getStreams({ center_id: centerId, course_id: courseId, batch_type_id: batchTypeId, exam_id: key }))
        }
        if (event === "batch") {
            dispatch(getBatch({ center_id: centerId, course_id: courseId, batch_type_id: batchTypeId, exam_id: examId, stream_id: key }))
        }
    }
    const [candNames, setCandNames] = useState([] as any)
    const [candidate_id, set_candidate_id] = useState(null as any)

    const formProps = {
        name: "candidate-filters",
        enableEnterSubmit: true,
        formElements: [
            {
                name: "candidate_name",
                label: "",
                placeholder: "Search Candidate Name",
                type: "AutoComplete",
                options: candNames,
                allowClear: true,
                onSearch: (e: any) => {
                    if (e?.length > 2) {
                        dispatch(requestCandidateListMinimal({ body: { candidate_name: e } })).then((val: any) => {
                            if (val?.payload?.data?.data) {
                                setCandNames(val?.payload?.data?.data?.map((name: any) => {
                                    return { key: name._id, value: name.candidate_name }
                                }))
                            }
                        })
                    }
                },
                onSelect: (e: any, option: any) => {
                    if (option?.key) {
                        set_candidate_id(option.key)
                    } else {
                        set_candidate_id(null)
                    }
                },
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
            {
                name: "roll_no",
                label: "",
                placeholder: "Search Roll Number",
                type: "Input",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
            {
                name: "session_id",
                label: "",
                placeholder: "Search Session",
                type: "Select",
                options: sessions,
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
            {
                name: "center_id",
                label: "",
                placeholder: "Search Center",
                type: "Select",
                options: centers?.map((val: any) => {
                    return {
                        name: val.center_name, value: val._id
                    }
                }),
                onChange: (evt: any) => getRelevantData('course', evt),
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
            {
                name: "course_id",
                label: "",
                placeholder: "Search Course",
                type: "Select",
                options: courses?.map((val: any) => {
                    return {
                        name: val?.course_ref?.course_name, value: val?.course_ref?._id
                    }
                }),
                onChange: (evt: any) => getRelevantData('batchType', evt),
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
            {
                name: "batch_type_id",
                label: "",
                placeholder: "Search Batch Type",
                type: "Select",
                options: batchType?.map((val: any) => {
                    return {
                        name: val?.batch_type_id?.batch_type_name, value: val?.batch_type_id?._id
                    }
                }),
                onChange: (evt: any) => getRelevantData('exam', evt),
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
            {
                name: "exam_id",
                label: "",
                placeholder: "Search Exam",
                type: "Select",
                options: exams?.map((item: any) => {
                    return {
                        name: item?.exam_id?.exam_name, value: item?.exam_id?._id
                    }
                }),
                onChange: (evt: any) => getRelevantData('stream', evt),
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
            {
                name: "stream_id",
                label: "",
                placeholder: "Search Stream",
                type: "Select",
                options: streams?.map((item: any) => {
                    return {
                        name: item?.stream_id?.stream_name, value: item?.stream_id?._id
                    }
                }),
                onChange: (evt: any) => getRelevantData('batch', evt),
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
            {
                name: "batch_id",
                label: "",
                placeholder: "Search Batch",
                type: "Select",
                options: batch?.map((item: any) => {
                    return {
                        name: item?.batch_code_id?.batch_code, value: item?._id
                    }
                }),
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
        ],
        initialValues: {},
        extraButtons: [
            {
                htmlType: "submit",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
                loading: false,
                submitButtonText: "Clear",
                type: "clearForm",
            },
            {
                htmlType: "submit",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
                loading: false,
                submitButtonText: "Apply",
                submitButtonType: "primary",
            },
        ],
        onFinish: onFilterApply,
        onFinishFailed: null,
        onValuesChange: null,
    };
    const onChangeHandler = (_pagination: any, _filters: any, _sorter: any) => {
        if (_sorter.order === undefined) {
            setSorter({ sort_by: "", sort_type: "" });
        } else {
            let order = "";
            let columnKey = "";
            if (_sorter.order === "ascend") order = "asc";
            else order = "desc";

            if (_sorter.columnKey === "enabled") {
                columnKey = "status";
            } else if (_sorter.columnKey === "displayName") {
                columnKey = "name";
            } else {
                columnKey = _sorter.columnKey;
            }
            setSorter({ sort_by: columnKey, sort_type: order });
        }
    };
    const onChangeTab = (key: string) => {
        setPaidType(key)
    }



    return <>
        <div
            className="mx-6 mt-2 bg-primary-white-blueish border-primary-gray-02"
            style={{ borderBottom: "none!important" }}
        >
            <Row>
                <Col span={24}>
                    <ContentHeaderComponent
                        title="Manage Instalments"
                        subtitle=""
                        buttons={headerButtons}
                    />
                </Col>
            </Row>

            {filterVisible && (
                <Row className="bg-body">
                    <Col span={24} className="py-1">
                        <div className="px-3 table-filter-form">
                            <GenericFormComponent {...formProps} />
                        </div>
                    </Col>
                </Row>
            )}
            <Row>
                <Col span={24}>
                    <Tabs className="table-status-tabs mx-3" defaultActiveKey={paidType} onChange={(onChangeTab)}>
                        <Tabs.TabPane tab="Unrealized" key="unrealised"></Tabs.TabPane>
                        <Tabs.TabPane tab="Realized" key="realised"></Tabs.TabPane>
                    </Tabs>
                </Col>
            </Row>
        </div>
        <DataTableComponent
            loading={false}
            dataSource={instalmentsList}
            columns={columns}
            onChange={onChangeHandler}
            title={"Instalments List"}
            defaultPage={0}
            currentPage={pagination.page}
            totalRows={total}
            onPageChange={onPageChange}
            maxWidth="2400px"
        />

        {payRestAmount && <Drawer width={"80%"} open={payRestAmount != null} title={"Fill Remaining Installment"} onClose={() => setPayRestAmount(null)}>
            <NextInstalmentRealize closeModal={() => {
                setPayRestAmount(false)
                loadList()
            }} instalmentData={payRestAmount} />
        </Drawer>}


        {/* <Drawer width={"80%"} open={refund} title={"Refund Fee From"} onClose={() => setRefund(false)}>
            <Row>
                <Col span={24}>
                    <CandidateCard candidateData={[]} courseData={[]} withPic={true}></CandidateCard>
                </Col>
                <Col span={24} className={"mt-5"}>
                    <CandidateCenterCard candidateData={[]} courseData={[]} ></CandidateCenterCard>
                </Col>
                <Col span={24} className={"mt-2 marquee"}>
                    <p>
                        Student cross the 5 day limit [Batch Starting]
                    </p>
                </Col>
                <Col span={24}>
                    <CourseFeeDetails candidateData={[]} courseData={[]}></CourseFeeDetails>
                </Col>
                <Col span={24} className={"mt-5"}>
                    <Descriptions title="Fee Collection Payment Details" className="table-description">
                        <Descriptions.Item label="Application Image "><Upload {...props}>
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload></Descriptions.Item>
                        <Descriptions.Item label="Cancelled Cheque Image"><Upload {...props}>
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload></Descriptions.Item>
                        <Descriptions.Item label="Cheque/DD (In Favour of) *"><Input></Input></Descriptions.Item>
                        <Descriptions.Item label="Enter Remarks " span={24}><TextArea></TextArea></Descriptions.Item>
                    </Descriptions>
                    <Table
                        loading={false}
                        dataSource={[]}
                        columns={payColumns}
                        size="small"
                        pagination={false}
                    />
                </Col>
                <Col span={24} className={"text-right mt-10"}>
                    <Space>
                        <Button type="default">
                            Refund/Cancellation
                        </Button>
                        <Button type="default" onClick={() => setRefund(false)}>
                            Cancel Refund/Cancellation
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Drawer> */}

    </>


}
export default InstalmentList;


