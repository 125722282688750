import { Button, Col, Row, Space, notification } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { BiCloudDownload, BiFilterAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { requestCourseEnrolmentsAllDetails, setFilters } from "../../../core/store/reducers/admission.slice";
import { getBatch, getBatchType, getCenterList, getCourseListByCenter, getExams, getMasterDropdownData, getStreams } from "../../../core/store/reducers/masters.slice";
import ContentHeaderComponent from "../../../shared/components/content-header/content-header.component";
import DataTableComponent from "../../../shared/components/data-table/data-table.component";
import GenericFormComponent from "../../../shared/components/generic-form/generic-form.component";
import { TABLE_ROWS_COUNT } from "../../../shared/constants";
import { RootState } from "../../../shared/types/common";
import { removeUndefinedKeys } from "../../../shared/utils/utils";

let columnHeaders = [
    "S. No.",
    "Registration No.",
    "Candidate Name",
    "Roll No.",
    "Date of Birth",
    "Gender",
    "Category",
    "Contact No",
    "Email ID",
    "ID Proof Type",
    "ID Proof No",
    "Gr. College Name",
    "Gr. Passing Year",
    "Gr. Marks(%)",
    "PGr. College Name",
    "PGr. Passing Year",
    "PGr. Marks(%)",
    "Course Name",
    "Exam Name",
    "Stream Name",
    "Batch Type",
    "Batch Code",
    "Batch Time",
    "Brief Status",
    "Present Address State",
    "Present Address District",
    "Present Address",
    "Permanent Address State",
    "Permanent Address District",
    "Permanent Address",
    "Enrolment Counsellor",
    "Enquiry Counsellor",
    "Admission Date",
]
function AllDetailsEnrolmentsList() {
    const [filterVisible, setFilterVisible] = useState(true);
    const [enrolmentsList, setEnrolmentsList] = useState([] as any);
    const dispatch = useDispatch();
    const { enrolments, filters, total } = useSelector((state: RootState) => state.admission);
    // Actions and selections
    const [pagination, setPagination] = useState({ page: 1, limit: TABLE_ROWS_COUNT });

    console.log(filters)

    const loadList = () => {
        if (filters?.created_on) {
            dispatch(requestCourseEnrolmentsAllDetails({ body: filters }))?.then((val: any) => {
                if (val?.payload?.data?.data) {
                    setEnrolmentsList(val?.payload?.data?.data)
                }
                if (val?.payload?.data?.data?.length == 0) {
                    notification.warning({ message: val?.payload?.data?.message })
                }
            });
        }

    }

    useEffect(() => {
        loadList();
    }, [filters])

    const onPageChange = (pageNumber: number) => {
        setPagination({
            page: pageNumber,
            limit: pagination.limit,
        });
    };

    const columns = columnHeaders?.map((val: any) => {
        return {
            title: val,
            dataIndex: val,
            key: val
        }
    });
    const headerButtons = (
        <Space>
            <CSVLink filename="all_details.csv" data={enrolmentsList}>
                <Button
                    type="primary"
                    icon={
                        <span role="img" className="anticon">
                            <BiCloudDownload />
                        </span>
                    }
                    disabled={enrolmentsList?.length == 0}
                >Download</Button>
            </CSVLink>
            <Button
                shape="circle"
                type="primary"
                icon={
                    <span role="img" className="anticon">
                        <BiFilterAlt />
                    </span>
                }
                onClick={() => {
                    setFilterVisible(!filterVisible);
                }}
            ></Button>
        </Space>
    );
    const onFilterApply = (values: any) => {
        let filter = {} as any;
        values = removeUndefinedKeys(values)
        for (let [key, value] of Object.entries(values)) {
            if (value !== "" && value && key != 'range') {
                filter[key] = value;
            }
            if (key == 'range') {
                filter.created_on = {
                    $gte: new Date(moment(values.range[0]).format('YYYY-MM-DD')),
                    $lte: new Date(moment(values.range[1]).format('YYYY-MM-DD'))
                };
            }
        }
        dispatch(setFilters(filter)); //filters
    };
    const [courseId, setCourseId] = useState(null)
    const [centerId, setCenterId] = useState(null)
    const [batchTypeId, setBatchTypeId] = useState(null)
    const [examId, setExamId] = useState(null)
    const [sessions, setSessions] = useState([] as any);
    const { centers, courses, batchType, exams, streams, batch } = useSelector((state: RootState) => state.masters);
    useEffect(() => {
        dispatch(getCenterList())
        dispatch(getMasterDropdownData({ schemaKey: "session", labelKey: "session" })).then((val: any) => {
            if (val?.payload?.data?.success) {
                setSessions(val?.payload?.data?.data?.map((val: any) => {
                    return { label: val.session, value: val._id }
                }))
            }
        })
    }, [])
    const getRelevantData = (event: any, key: any) => {
        if (event === "course") {
            setCenterId(key)
            dispatch(getCourseListByCenter({ center_id: key }))
        }
        if (event === "batchType") {
            setCourseId(key)
            dispatch(getBatchType({ center_id: centerId, course_id: key }))
        }
        if (event === "exam") {
            setBatchTypeId(key)
            dispatch(getExams({ center_id: centerId, course_id: courseId, batch_type_id: key }))
        }
        if (event === "stream") {
            setExamId(key)
            dispatch(getStreams({ center_id: centerId, course_id: courseId, batch_type_id: batchTypeId, exam_id: key }))
        }
        if (event === "batch") {
            dispatch(getBatch({ center_id: centerId, course_id: courseId, batch_type_id: batchTypeId, exam_id: examId, stream_id: key }))
        }
    }
    const formProps = {
        name: "candidate-filters",
        enableEnterSubmit: true,
        formElements: [
            {
                name: "range",
                label: "",
                type: "RangePicker",
                disabledDate: (d: any) => {
                    return d > moment().add(1, 'd');
                },
                colSpanResponsive: { xs: 24, sm: 12, md: 12, lg: 8 },
            },
            {
                name: "roll_no",
                label: "",
                placeholder: "Search Roll Number",
                type: "Input",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
            {
                name: "session_id",
                label: "",
                placeholder: "Search Session",
                type: "Select",
                options: sessions,
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
            {
                name: "center_id",
                label: "",
                placeholder: "Search Center",
                type: "Select",
                options: centers?.map((val: any) => {
                    return {
                        name: val.center_name, value: val._id
                    }
                }),
                onChange: (evt: any) => getRelevantData('course', evt),
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
            {
                name: "course_id",
                label: "",
                placeholder: "Search Course",
                type: "Select",
                options: courses?.map((val: any) => {
                    return {
                        name: val?.course_ref?.course_name, value: val?.course_ref?._id
                    }
                }),
                onChange: (evt: any) => getRelevantData('batchType', evt),
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
            {
                name: "batch_type_id",
                label: "",
                placeholder: "Search Batch Type",
                type: "Select",
                options: batchType?.map((val: any) => {
                    return {
                        name: val?.batch_type_id?.batch_type_name, value: val?.batch_type_id?._id
                    }
                }),
                onChange: (evt: any) => getRelevantData('exam', evt),
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
            {
                name: "exam_id",
                label: "",
                placeholder: "Search Exam",
                type: "Select",
                options: exams?.map((item: any) => {
                    return {
                        name: item?.exam_id?.exam_name, value: item?.exam_id?._id
                    }
                }),
                onChange: (evt: any) => getRelevantData('stream', evt),
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
            {
                name: "stream_id",
                label: "",
                placeholder: "Search Stream",
                type: "Select",
                options: streams?.map((item: any) => {
                    return {
                        name: item?.stream_id?.stream_name, value: item?.stream_id?._id
                    }
                }),
                onChange: (evt: any) => getRelevantData('batch', evt),
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },
            {
                name: "batch_id",
                label: "",
                placeholder: "Search Batch",
                type: "Select",
                options: batch?.map((item: any) => {
                    return {
                        name: item?.batch_code_id?.batch_code, value: item?._id
                    }
                }),
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
            },



        ],
        initialValues: {},
        setValues: { ...filters },
        extraButtons: [
            {
                htmlType: "submit",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
                loading: false,
                submitButtonText: "Clear",
                type: "clearForm",
            },
            {
                htmlType: "submit",
                colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
                loading: false,
                submitButtonText: "Apply",
                submitButtonType: "primary",
            },
        ],
        onFinish: onFilterApply,
        onFinishFailed: null,
        onValuesChange: null,
    };
    return <>
        <div
            className="mx-6 mt-2 bg-primary-white-blueish border-primary-gray-02"
            style={{ borderBottom: "none!important" }}
        >
            <Row>
                <Col span={24}>
                    <ContentHeaderComponent
                        title="All Details"
                        subtitle=""
                        buttons={headerButtons}
                    />
                </Col>
            </Row>

            {filterVisible && (
                <Row className="bg-body">
                    <Col span={24} className="py-1">
                        <div className="px-3 table-filter-form">
                            <GenericFormComponent {...formProps} />
                        </div>
                    </Col>
                </Row>
            )}
        </div>
        <DataTableComponent
            loading={false}
            dataSource={enrolmentsList}
            columns={columns}
            onChange={() => { }}
            title={"ENrolments List"}
            defaultPage={0}
            currentPage={pagination.page}
            totalRows={enrolmentsList?.length}
            pagination={false}
            onPageChange={onPageChange}
            maxWidth="1500px"
        />

    </>


}
export default AllDetailsEnrolmentsList;


