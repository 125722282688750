import { EditOutlined, PlusOutlined } from "@ant-design/icons";

import { alphaNumericPattern, formatDate } from "../../../shared/utils/utils";

import {
  Button,
  Col, Row, Space, Tooltip
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../assets/styles/user-management.less";
import {
  getUserList,
  setFilters
} from "../../../core/store/reducers/user-management.slice";
import ContentHeaderComponent from "../../../shared/components/content-header/content-header.component";
import DataTableComponent from "../../../shared/components/data-table/data-table.component";
import GenericFormComponent, {
  GenericFormProps
} from "../../../shared/components/generic-form/generic-form.component";
import { TABLE_ROWS_COUNT } from "../../../shared/constants";
import { RootState } from "../../../shared/types/common";
import CreateEditUser from "./create-edit-user.page";

const ManageUsersPage: React.FC = () => {
  const dispatch = useDispatch();

  // User list states
  const { loadingUsers, userList, filters, total } =
    useSelector((state: RootState) => state.userManagement);
  const { screenType } = useSelector((state: RootState) => state.settings);
  const { user } = useSelector((state: RootState) => state.auth);
  // User list local state
  const [entityListData, setEntityListData] = useState([] as any);

  // Flag to open create/edit form
  const [createEditOpen, setCreateEditOpen] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState(null as any);
  const [filterVisible, setFilterVisible] = useState(false);

  // Actions and selections
  const [sorter, setSorter] = useState({ sort_by: "", sort_type: "" });
  const [pagination, setPagination] = useState({ page: 1, limit: TABLE_ROWS_COUNT });


  // Functions and Constants
  const loadList = useCallback(
    (_page: any = 1) => {
      const data = {
        ...pagination,
        ...sorter,
      };
      dispatch(getUserList({ params: data, body: filters }));
    },
    [filters, dispatch, sorter, pagination]
  );

  const createEntityListner = () => {
    setCreateEditOpen(true);
    setSelectedEntity(null);
  };
  // Functions and Constants
  // const loadList = useCallback(
  //   (_page: any = 1) => {
  //     const data = {
  //       queryString: { ...pageNumber, ...sorter },
  //       body: filters,
  //     };
  //     dispatch(getUserList(data));
  //     dispatch(getUserCount());
  //     setHeaderButtons(
  //       <Space>
  //         <Button type="primary" size="large" onClick={createUserListener}>
  //           <span className="font-size-14">Create New User</span>
  //         </Button>
  //         <Tooltip placement="left" title={"Open Filters"}>
  //           <Button
  //             type="primary"
  //             className="filter-icon"
  //             icon={<FilterIconComponent icon={FilterIcon} />}
  //             size="large"
  //             onClick={() => toggleFilters((prevState) => (prevState ? 0 : 1))}
  //           ></Button>
  //         </Tooltip>
  //       </Space>
  //     );
  //   },
  //   [filters, dispatch, sorter, pageNumber]
  // );

  // const createUserListener = () => {
  //   setCreateEditOpen(true);
  //   setSelectedUser(null);
  // };

  // useEffect Hooks
  useEffect(() => {
    loadList();
  }, [loadList]);
  //filters, loadList
  useEffect(() => {
    userList?.length >= 0 &&
      setEntityListData(
        userList.map((obj: any, index: number) => {
          return { key: index, ...obj };
        })
      );
  }, [userList]);

  const onFilterApply = (values: any) => {
    let arrFilter = [];
    for (let [key, value] of Object.entries(values)) {
      if (value !== "") {
        arrFilter.push({ key: key, value: value });
      }
    }
    let refinedArray = arrFilter.filter((data) => {
      return data.value !== undefined;
    });
    let obj = {} as any;
    refinedArray.forEach((data: any) => {
      obj[data.key] = data.value;
    });
    setPagination({
      page: 1,
      limit: pagination.limit,
    });
    dispatch(setFilters(obj)); //filters
  };

  const onPageChange = (pageNumber: number) => {
    setPagination({
      page: pageNumber,
      limit: pagination.limit,
    });
  };

  const editEntityListner = (entity: any) => {
    setSelectedEntity(entity);
    setCreateEditOpen(true);
  };

  const onChangeHandler = (_pagination: any, _filters: any, _sorter: any) => {
    if (_sorter.order === undefined) {
      setSorter({ sort_by: "", sort_type: "" });
    } else {
      let order = "";
      let columnKey = "";
      if (_sorter.order === "ascend") order = "asc";
      else order = "desc";

      if (_sorter.columnKey === "enabled") {
        columnKey = "status";
      } else if (_sorter.columnKey === "displayName") {
        columnKey = "name";
      } else {
        columnKey = _sorter.columnKey;
      }
      setSorter({ sort_by: columnKey, sort_type: order });
    }
  };



  const columns = [
    {
      title: "Emp. Code",
      dataIndex: "emp_code",
      sorter: true,
      key: "emp_code",
    },
    {
      title: "Username",
      dataIndex: ["u_id", "user_name"],
      sorter: true,
      key: "user_name",
    },
    {
      title: "Name",
      dataIndex: "name",
      sorter: true,
      key: "name",
    },
    {
      title: "EMAIL ID",
      dataIndex: "email",
      sorter: true,
      key: "email",
    },
    {
      title: "PHONE NUMBER",
      dataIndex: "phone",
      sorter: true,
      key: "phone",
    },
    {
      title: "Role",
      dataIndex: "user_role",
      sorter: true,
      key: "user_role",
    },
    {
      title: "Created On",
      dataIndex: "created_on",
      render: (data: any) => formatDate(data, "MMM DD, Y hh:mm A"),
      sorter: true,
      key: "created_on",
    },
    {
      title: "Status",
      dataIndex: ["u_id", "status"],
      sorter: true,
      key: "enabled",
      render: (text: any, _record: any, index: any) => (
        <>
          {text ? (
            <div key={index}>
              <span className="active roundAct"></span>
              <span className="active">Active</span>
            </div>
          ) : (
            <div color="#CE092E" key={index}>
              <span color="#CE092E" className=" inactive roundInact"></span>
              <span className="inactive">Inactive</span>
            </div>
          )}
        </>
      ),
    },
    {
      title: "Actions",
      width: "160px",
      render: (a: any) => {
        return (
          <>
            <Tooltip title={"Edit User"}>
              <Button
                type="link"
                className="table-button"
                onClick={() => editEntityListner(a)}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
      key: "actions",
    },
  ];


  const formProps: GenericFormProps = {
    name: "user-filters",
    formElements: [
      {
        name: "name",
        label: "",
        placeholder: "Name Search",
        type: "Input",
        colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
        rules: [
          {
            max: 30,
            message: "Max length in 30 chars",
          },
          {
            pattern: alphaNumericPattern,
            message: "Only alphanumeric chars allowed",
          },
        ],
      },
      // {
      //   name: "userId",
      //   label: "",
      //   placeholder: "Search User Id",
      //   type: "Input",
      //   colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
      //   rules: [
      //     {
      //       max: 50,
      //       message: "Max length in 50 chars",
      //     },
      //   ],
      // },
      // {
      //   name: "phoneNumber",
      //   label: "",
      //   placeholder: "Search Phone Number",
      //   type: "InputNumber",

      //   colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 4 },
      //   rules: [
      //     () => ({
      //       validator(_: any, value: any) {
      //         if (value && `${value}`.length > 20) {
      //           return Promise.reject(new Error("Max length"));
      //         }
      //         if (value && value < 0) {
      //           return Promise.reject(new Error("Negative value"));
      //         }
      //         if (value && value.indexOf(".") !== -1) {
      //           return Promise.reject(new Error("Decimal value"));
      //         }
      //         return Promise.resolve();
      //       },
      //     }),
      //   ],
      // },
    ],
    initialValues: {
      // name: "",
      // userId: "",
      // userGroups: "",
      // email: "",
      // status: "",
      // phoneNumber: "",
      // credentialSource: "",
    },
    extraButtons: [
      // {
      //   htmlType: "submit",
      //   colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
      //   loading: false,
      //   submitButtonText: "Clear",
      //   type: "clearForm",
      // },
      // {
      //   htmlType: "submit",
      //   colSpanResponsive: { xs: 12, sm: 8, md: 6, lg: 3 },
      //   loading: false,
      //   submitButtonText: "Apply",
      //   submitButtonType: "primary",
      // },
    ],
    onFinish: onFilterApply,
    onFinishFailed: null,
    onValuesChange: null,
  };
  const createEditSubmit = () => {
    setCreateEditOpen(false);
    loadList();
  };

  const headerButtons = (
    <Space>
      <Button
        type="primary"
        // size="large"
        icon={<PlusOutlined />}
        onClick={createEntityListner}
      >
        <span className="font-size-14">New {!screenType?.isPortrait && ' User'}</span>
      </Button>
      {/* <Button
        shape="circle"
        type="primary"
        icon={
          <span role="img" className="anticon">
            <BiFilterAlt />
          </span>
        }
        onClick={() => {
          setFilterVisible(!filterVisible);
        }}
      ></Button> */}
    </Space>
  );
  return (
    <>
      <div
        className="mx-6 mt-2 bg-primary-white-blueish border-primary-gray-02"
        style={{ borderBottom: "none!important" }}
      >
        <Row>
          <Col span={24}>
            <ContentHeaderComponent
              title={"Manage Users"}
              subtitle=""
              buttons={headerButtons}
            />
          </Col>
        </Row>
        {filterVisible && (
          <Row className="bg-body">
            <Col span={24} className="py-1">
              <div className="px-3 table-filter-form">
                <GenericFormComponent {...formProps} />
              </div>
            </Col>
          </Row>
        )}
      </div>
      <DataTableComponent
        loading={loadingUsers}
        dataSource={entityListData}
        columns={columns}
        onChange={onChangeHandler}
        title={"User List"}
        defaultPage={0}
        currentPage={pagination.page}
        totalRows={total}
        onPageChange={onPageChange}

      />

      {createEditOpen && (
        <CreateEditUser
          onClose={() => setCreateEditOpen(false)}
          visible={createEditOpen}
          selectedUser={selectedEntity}
          onSubmit={createEditSubmit}
        />
      )}
    </>
  );
};
export default ManageUsersPage;
