import { Drawer } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCenterList, getMasterDropdownData } from "../../../core/store/reducers/masters.slice";
import {
  createNewUser,
  getUserListDD,
  updateEmployee
} from "../../../core/store/reducers/user-management.slice";
import GenericFormComponent, {
  GenericFormProps
} from "../../../shared/components/generic-form/generic-form.component";
import { RootState } from "../../../shared/types/common";
import { showNotification } from "../../../shared/utils/utils";

interface Props {
  onClose: () => void;
  visible: boolean;
  selectedUser?: any;
  onSubmit?: () => void;
}
const CreateEditUser = ({
  onClose,
  visible,
  selectedUser = null,
  onSubmit = () => {
    return;
  },
}: Props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: RootState) => state.auth);
  const [userRoles, setUserRoles] = useState([
    { value: 'admin' },
    { value: 'counsellor' },
    { value: 'accounts' },
    { value: 'webmaster' },
    { value: 'center-head' },
    { value: 'lms-admin' },
    { value: 'faculty' },
    { value: 'live-coordinator' },
    { value: 'marketing' },
  ]);
  const [centers, setCenters] = useState([] as any);
  const [formValues, setFormValues] = useState(null as any);
  const [subjects, setSubjects] = useState([] as any);
  const [hodList, setHodList] = useState([] as any)
  useEffect(() => {
    dispatch(getCenterList()).then((val: any) => {
      if (val?.payload?.data?.data?.length > 0) {
        setCenters(
          val.payload.data.data.map((val: any) => {
            return { label: val.center_name, value: val._id };
          })
        );
      }
    });
    dispatch(getMasterDropdownData({ schemaKey: "exam-subject", labelKey: "name" })).then((val: any) => {
      if (val?.payload?.data?.success) {
        setSubjects(val?.payload?.data?.data?.map((val: any) => {
          return { name: val.name, value: val._id }
        }))
      }
    })
    dispatch(getUserListDD({ params: { page: 1, limit: 5000 }, body: { role: { $ne: "counsellor" } } })).then((val: any) => {
      if (val?.payload?.data?.data) {
        setHodList(val?.payload?.data?.data)
      }
    });
    if (selectedUser) {
      setFormValues({
        username: selectedUser?.u_id?.user_name ? selectedUser?.u_id?.user_name : null,
        emp_code: selectedUser?.emp_code ? selectedUser?.emp_code : null,
        name: selectedUser?.name ? selectedUser?.name : null,
        phone: selectedUser?.phone ? selectedUser?.phone : null,
        role: selectedUser?.user_role ? selectedUser?.user_role : null,
        center: selectedUser?.center?._id ? selectedUser?.center?._id : null,
        hod: selectedUser?.hod ? selectedUser?.hod : null,
        subject_id: selectedUser?.subject_id ? selectedUser?.subject_id : null,
        status: selectedUser?.u_id?.status ?? true
      })
    }
  }, [])

  const onFinish = (values: any) => {
    if (selectedUser) {
      const payload = {
        "id": selectedUser._id,
        "u_id": selectedUser.u_id._id,
        "status": values.status,
        "modified_by": user._id,
        "profile_data": {
          "emp_code": values.emp_code,
          "name": values.name,
          "phone": values.phone,
          "user_role": values.role,
          "center": values.center,
          "subject_id": values.subject_id,
          "hod": values.hod
        }
      };
      dispatch(updateEmployee(payload)).then((val: any) => {
        if (val?.payload?.data) {
          showNotification("User updated successfully", "success");
          onSubmit();
        }
      });
    } else {
      const payload = {
        "user_type": "employee",
        "user_name": values.username,
        "password": values.password,
        "created_by": user._id,
        "modified_by": user._id,
        "status": values.status,
        "profile_data": {
          "emp_code": values.emp_code,
          "name": values.name,
          "email": values.username,
          "phone": values.phone,
          "user_role": values.role,
          "center": values.center,
          "subject_id": values.subject_id,
          "hod": values.hod
        }
      };
      dispatch(createNewUser(payload)).then((val: any) => {
        if (val?.payload?.data) {
          showNotification("USER.USER_CREATED_SUCCESSFULLY", "success");
          onSubmit();
        }
      });
    }
  };

  const onFinishFailed = (_errorInfo: any) => {
    return;
  };
  const onValuesChange = (value: any, values: any) => {
    setFormValues(values)
  }
  const formProps: GenericFormProps = {
    name: "creat-edit-user",
    heading: "",
    formElements: [
      {
        name: "username",
        label: "Username",
        placeholder: "Username",
        type: "Input",
        colSpan: 12,
        rules: [
          {
            required: true,
            message: "Required",
          },
          {
            type: "email",
            message: "Should be valid Email ID",
          },
          {
            max: 50,
            message: "Max length is 50",
          },
        ],
        disabled: selectedUser
      },
      {
        name: "password",
        label: "Password",
        placeholder: "***********",
        type: "Input",
        colSpan: 12,
        rules: [
          ...[!selectedUser ? {
            required: true,
            message: "Required",
          } : {}],
          {
            max: 16,
            message: "Max length is 16",
          },
        ],
        disabled: selectedUser
      },
      {
        name: "emp_code",
        label: "Employee Code",
        placeholder: "Employee Code",
        type: "Input",
        colSpan: 12,
        rules: [
          {
            required: true,
            message: "Required",
          },
          {
            max: 50,
            message: "Max length is 50",
          },
        ],
      },
      {
        name: "name",
        label: "Name",
        placeholder: "Name",
        type: "Input",
        colSpan: 12,
        rules: [
          {
            required: true,
            message: "Required",
          },
          {
            max: 50,
            message: "Max length is 50",
          },
        ],
      },
      {
        name: "phone",
        label: "Phone Number",
        placeholder: "Phone Number",
        type: "InputNumber",
        colSpan: 12,
        controls: false,
        rules: [
          () => ({
            validator(_: any, value: any) {
              if (value && `${value}`.length > 20) {
                return Promise.reject(new Error("COMMON.ERROR.MAX_LENGTH"));
              }
              if (value && value <= 0) {
                return Promise.reject(new Error("COMMON.ERROR.NEGATIVE_VALUE"));
              }
              if (value && value.indexOf(".") !== -1) {
                return Promise.reject(new Error("COMMON.ERROR.DECIMAL_VALUE"));
              }
              return Promise.resolve();
            },
          }),
        ],
      },
      {
        name: "role",
        label: "User Role",
        placeholder: "User Role",
        type: "Select",
        colSpan: 12,
        showSearch: true,
        allowClear: true,
        options: userRoles,
      },
      {
        name: "center",
        label: "Center",
        placeholder: "Center",
        type: "Select",
        colSpan: 12,
        showSearch: true,
        allowClear: true,
        options: centers,
      },
      {
        name: "hod",
        label: "HOD/Supervisor",
        placeholder: "HOD/Supervisor",
        type: "Select",
        colSpan: 12,
        showSearch: true,
        allowClear: true,
        options: hodList?.map((val: any) => { return { label: val.name + " (" + val.emp_code + ")", value: val._id } }),
      },
      ...(
        formValues?.role === 'faculty' ? [{
          name: "subject_id",
          label: "Subject",
          placeholder: "Subject",
          type: "Select",
          colSpan: 12,
          showSearch: true,
          mode: 'multiple',
          allowClear: true,
          options: subjects,
        }] : []
      ),
      {
        name: "status",
        label: "Status",
        placeholder: "Status",
        type: "Select",
        colSpan: 12,
        options: [{ label: "Active", value: true }, { label: "Inactive", value: false }],
      },
    ],
    initialValues: {
      username: selectedUser?.u_id?.user_name ? selectedUser?.u_id?.user_name : null,
      emp_code: selectedUser?.emp_code ? selectedUser?.emp_code : null,
      name: selectedUser?.name ? selectedUser?.name : null,
      phone: selectedUser?.phone ? selectedUser?.phone : null,
      role: selectedUser?.user_role ? selectedUser?.user_role : null,
      center: selectedUser?.center?._id ? selectedUser?.center?._id : null,
      hod: selectedUser?.hod ? selectedUser?.hod : null,
      subject_id: selectedUser?.subject_id ? selectedUser?.subject_id : null,
      status: selectedUser?.u_id?.status ?? true
    },
    extraButtons: [
      {
        htmlType: "button",
        colSpan: 5,
        loading: false,
        submitButtonText: "CANCEL",
        onClick: onClose,
      },
      {
        htmlType: "button",
        colSpan: 5,
        loading: false,
        submitButtonText: "CLEAR",
        type: "clearForm",
      },
      {
        htmlType: "submit",
        colSpan: 5,
        loading: false,
        submitButtonText: "SAVE",
        submitButtonType: "primary",
      },
    ],
    onFinish: onFinish,
    onFinishFailed: onFinishFailed,
    onValuesChange: onValuesChange,
  };
  return (
    <>
      <Drawer
        maskClosable={false}
        title="Manage User"
        className="form-drawer"
        width={720}
        onClose={onClose}
        visible={visible}
      >
        <GenericFormComponent {...formProps} />
      </Drawer>
    </>
  );
};

export default CreateEditUser;
